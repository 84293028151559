@font-face {
  font-family: 'Vampire';
  font-style: normal;
  font-weight: 60;
  src: url('./Vampire-Wars-Italic.woff'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Heiro';
  font-style: normal;
  font-weight: 100;
  src: url('./HeirofLightRegular.woff');
}
