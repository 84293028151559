@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
  background-position: center;
}
.App {
  text-align: center;
  font-size: 10vw;
  overflow-x: hidden;
  overflow-y: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.swiper > .swiper-wrapper{
  transition-timing-function : linear;
}

.swiper-button-next {
  background: url(./assets/images/NextBtn.svg) no-repeat;
  background-size: 50% auto;
  background-position: center;
}

.swiper-button-prev {
  background: url(./assets/images/PrevBtn.svg) no-repeat;
  background-size: 50% auto;
  background-position: center;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

.en {
  font-family: 'Montserrat', sans-serif;
}

.ko {
  font-family: 'Noto Sans', sans-serif;
}

.zh {
  font-family: 'Noto Sans', sans-serif;
}

.en-us {
  font-family: 'Montserrat', sans-serif;
}

.ko-kr {
  font-family: 'Noto Sans', sans-serif;
}

.zh-cn {
  font-family: 'Noto Sans', sans-serif;
}

.storytitle {
  font-family: "Heiro";
}

.zh .storytitle {
  font-family: "Noto Sans", sans-serif;
}

.zh-cn .storytitle {
  font-family: "Noto Sans", sans-serif;
}

@media (hover: hover) {
  #scrollable {
    overflow-x: hidden;
    overflow-y: auto !important;
    height: 100vh;
  }
}

h2 {
  font-family: 'Vampire';
  font-size: 2.5rem;
  font-weight: normal;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
